import './Locations.css'

declare var window :any;

function Locations() {

    const isMobile = ()=>{
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
            if (/android/i.test(userAgent)) {
                return "android";
            }
        
            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
        
            return "other";
    }
    
    return (
        <div style={{padding:20}}>
            <img src={require("./State-Farm-Logo-Red.png").default} style={{ maxWidth: 170, width:"40%"}} alt="State Farm Logo"/>

            <p className="date-header">Thursday, November 4</p>
            <div className="location-container">
                <div className="item-container">
                    <div className="item">
                        <strong>University of Kansas</strong>
                        <span>10AM - 4PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=38.9592130123559,-95.24348301781095" : isMobile() === 'iOS' ? "maps://?q=38.9592130123559,-95.24348301781095" : "https://goo.gl/maps/byhg8yMgGRnYcy3eA"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>University of Missouri</strong>
                        <span>10AM - 3PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=38.94412883104398,-92.32759937304955": isMobile() === 'iOS' ? "maps://?q=38.94412883104398,-92.32759937304955" : "https://goo.gl/maps/5hpyM9wF4EvoqbXW9"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
            </div>

            <p className="date-header">Friday, November 5</p>
            <div className="location-container">
                <div className="item-container">
                    <div className="item">
                        <strong>University of Kansas</strong>
                        <span>10AM - 2PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=38.9592130123559,-95.24348301781095": isMobile() === 'iOS' ? "maps://?q=38.9592130123559,-95.24348301781095" : "https://goo.gl/maps/byhg8yMgGRnYcy3eA"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>Westport (Harpo’s)</strong>
                        <span>5PM - 10PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.05251602614384,-94.59106411747113": isMobile() === 'iOS' ? "maps://?q=39.05251602614384,-94.59106411747113" : "https://goo.gl/maps/evpaqApqy8DDR2QbA"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>University of Missouri</strong>
                        <span>10AM - 3PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=38.94412883104398,-92.32759937304955": isMobile() === 'iOS' ? "maps://?q=38.94412883104398,-92.32759937304955" : "https://goo.gl/maps/5hpyM9wF4EvoqbXW9"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
            </div>

            <p className="date-header">Saturday, November 6</p>
            <div className="location-container">
                <div className="item-container">
                    <div className="item">
                        <strong>Crown Center Square</strong>
                        <span>10AM - 2PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.082869684813076,-94.581402845719": isMobile() === 'iOS' ? "maps://?q=39.082869684813076,-94.581402845719" : "https://goo.gl/maps/Qk3FZuhxMXLiQT1E9"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>Westport (Harpo’s)</strong>
                        <span>4PM - 10PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.05251602614384,-94.59106411747113": isMobile() === 'iOS' ? "maps://?q=39.05251602614384,-94.59106411747113" : "https://goo.gl/maps/evpaqApqy8DDR2QbA"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>KC Live!</strong>
                        <span>3PM - 8PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.09774959518243,-94.58162080212631": isMobile() === 'iOS' ? "maps://?q=39.09774959518243,-94.58162080212631" : "https://g.page/kcliveblock?share"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
            </div>

            <p className="date-header">Sunday, November 7</p>
            <div className="location-container">
                <div className="item-container">
                    <div className="item">
                        <strong>Arrowhead Stadium</strong>
                        <span>11AM - 3:25PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.05030965131703,-94.48241368046084": isMobile() === 'iOS' ? "maps://?q=39.05030965131703,-94.48241368046084" : "https://goo.gl/maps/8orCfsdd3HksYonf6"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
                <div className="item-container">
                    <div className="item">
                        <strong>KC Live!</strong>
                        <span>12PM - 6PM</span>
                    </div>
                    <a href={isMobile() === 'android' ? "geo:0,0?q=39.09774959518243,-94.58162080212631": isMobile() === 'iOS' ? "maps://?q=39.09774959518243,-94.58162080212631" : "https://g.page/kcliveblock?share"} target="_blank"
                    rel="noreferrer">
                        Open Map
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Locations;