import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useState, useEffect } from 'react';
import './App.css';
import Locations from './Locations';
import CookieConsent from "react-cookie-consent";
import Faq from './Faq';
// import Pre from './Pre';
// import Main from './Main';
import Ended from './Ended';

function App() {

	const [cookiesMore, setCookiesMore] = useState(false)

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize()
	})

	const onResize = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}



	return (
		<BrowserRouter basename={process.env.PUBLIC_URL} >
			<Switch>
				<Route path="/locations">
					<Locations />
				</Route>
				<Route path="/faq">
					<Faq />
				</Route>
				<Route path="/press">
					<Ended/>
				</Route>
				<Route path="/main">
					<Ended/>
				</Route>
				<Route path="/">
					{/* <Main/> */}
					<Ended />
				</Route>
			</Switch>
			<CookieConsent
				location="bottom"
				buttonText="X"
				cookieName="accept_cookies"
				style={{
					background: "#fff",
					color: "#000",
					alignItems: "flex-start",
					flexWrap: "no-wrap"
				}}
				contentStyle={{ fontSize: 12, flex: 1 }}
				buttonStyle={{
					backgroundColor: "#fff",
					color: "#000",
					fontSize: "30px",
					fontWeigth: "bold",
					margin: 0
				}}
				expires={150}
			>
				{!cookiesMore ?
					<div><strong>We value your privacy.</strong> We may collect personal information from you for business, marketing, and commercial purposes.{" "}<span style={{ color: '#e81e1e' }} onClick={() => setCookiesMore(true)}>Read more</span></div>
					:
					<div>
						<p><strong>We value your privacy.</strong> We may collect personal information from you such as identifying information (name, address, driver's license number), transactional information (products or services purchased and payment history), digital network activity (interactions with our website, IP address), geo-location data, audio recordings and other forms of personal information. We use this information for business, marketing and commercial purposes, including but not limited to, providing the products and services you request, processing your claims, protecting against fraud, maintaining security, confirming your identity and offering you other insurance and financial products.</p>
						<p>For California residents, click <a style={{ fontWeight: 'bold', color: '#e81e1e', textDecoration: 'none' }} href="https://www.statefarm.com/customer-care/privacy-security" target="_blank" rel="noopener noreferrer">here</a> to view the full version of the California Consumer Privacy Notice.</p>
						<p>Para español, haga clic <a style={{ fontWeight: 'bold', color: '#e81e1e', textDecoration: 'none' }} href="https://es.statefarm.com/atencion-al-cliente/privacidad-seguridad" target="_blank" rel="noopener noreferrer">aquí</a> para ver la versión completa del Aviso de Privacidad del Consumidor de California. <span style={{ color: '#e81e1e' }} onClick={() => setCookiesMore(false)}>Read less</span></p>
					</div>}
			</CookieConsent>
		</BrowserRouter>
	);
}

export default App;
