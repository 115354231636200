import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './Faq.css';

function Faq() {

    return (
        <div style={{ padding: 20, height: "100%", boxSizing: 'border-box', width: "100%" }}>
            <img src={require("./State-Farm-Logo.png").default} style={{ maxWidth: 170, width: "40%", marginBottom: 20 }} alt="State Farm Logo" />
            <Accordion allowZeroExpanded onChange={(e) => { document.getElementById(`accordion__heading-${e}`)?.scrollIntoView({ behavior: 'smooth' }) }}>
            <p>PRIZE REDEMPTION</p>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I see my prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Prizes can be found on the Neighborhood Quest homepage. Select your assigned gift after completing each level, then swipe your finger over the applicable State Farm logo to reveal your prize. Open your prize(s) to learn how to redeem each one.
                        <br></br>
                        <br></br>
                        If you cannot see your prizes, you might be in the “Neighborhood Quest” game
                        section. Simply click the "X" in the top left corner of your mobile device to go back to the State Farm Neighborhood Quest home page.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Will I be taxed on prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Winners are solely responsible for any and all federal, state, municipal and local taxes, fees
                        and other government assessments associated with receipt and use of a prize or
                        participation in this Contest. The value of any prize may be reported as a wage on the
                        winner's State Farm W-2 form. If this occurs, the winner will see reference to it on their
                        paycheck along with an increase in wages for any tax withholding the Company has paid on
                        your behalf.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I redeem my prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <strong>Hub and Bloomington employees</strong> assigned to a Bloomington (Corporate. Corporate South.
                        MRSF. ILOC. Oakland Ave. Airport Hanger. SF Park. Research Lab. Flex Storage Facility) or
                        Hub location (Cityline 1-4, Citylink. Marina Heights 2-5. Marinalink, Park Center 2/3) must
                        redeem their prizes at our Good Neighbor Fest block parties. Block parties are scheduled for June 15, 23 and 28, 2022 from 10am - 6pm local time.
                    </p>
                    <p>
                        If you are eligible to redeem digital rewards, they can be found in your wallet after the completion of each level. Redeem your prize(s) by opening the respective gift for each level and swiping across the State Farm logo to reveal your item.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        When will I receive my prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Physical prizes must be redeemed at one of the onsite block parties (6/15/22, 6/23/22 or 6/28/22) in
                        your assigned location. Redemption of swag prizes will ONLY be offered during the onsite
                        block parties. Digital prizes will be redeemed through Vatom wallet at the time of game
                        play. All digital prizes must be redeemed by Dec. 31, 2022.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I redeem my gift cards?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        For Gift Cards, type the code provided into the retailer’s redemption website. For Starbucks gift cards, you can also scan the Barcode at Starbucks Locations and use as a traditional gift card. Please check your balance on the retailer’s site. The NFT Coupon with the code will not update or be removed once the balance is spent.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Who do I contact if I have issues with my digital gift card(s)?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <a href="mailto:support@vatom.com">support@vatom.com</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I redeem my physical prizes at the State Farm Block Party?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Log into your Neighborhood Quest Wallet on your mobile device ahead of the State Farm Block Party. You will need access to your wallet to redeem. Show the Redemption tent attendee your phone with the prize page to confirm the prize you will receive. They will then show you a QR code to scan using the “Activate Camera to Redeem” button and QR scanner.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What if I did not register for a block party can I still attend to redeem my prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes – please find the Neighborhood Quest redemption booth and bring your phone to redeem your prizes.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Can another employee redeem my prizes?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        No, employees must claim their own prizes and must bring their phone with them to scan a QR code to redeem their prize(s).
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Can my prizes be mailed to me?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        No, all non-digital prizes must be picked up at an on-site block party. Exception: Level 4 physical prizes will be mailed to those not in Bloomington or a hub location.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What happens if I do not pick up my prize during one of the Good Neighbor Fest block parties?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Redemption of swag prizes will ONLY be offered during the onsite block parties. If you do not pick-up your prize during one of these events, you will forfeit your prize.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <p>NFT INFORMATION</p>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What is an NFT?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        An NFT is a Non-Fungible Token - a unique digital “token” that many see as a certificate of authenticity or a deed of proof confirming you own a digital object. NFTs are used to track unique digital items such as artwork, collectibles, or game items. State Farm has created a set of limited edition NFT Videos exclusively for the Neighborhood Quest.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What do I do with an NFT?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        You won a limited edition of this artwork that State Farm has published. The artwork in your wallet is yours to own, display, sell, or “drop” out in the world for someone else to find. What you decide to do with your NFT is completely up to you.

                    </p>
                    <p>
                        Once you have an NFT in your wallet, it is your property. Vatom SmartNFTs can be held and interacted with in the Wallet, displayed in Metaverse Spaces, sent between users, dropped on the map to view and pickup in Augmented Reality, and minted out to Non-Custodial NFT wallets like Metamask to re-sell on Opensea.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I see my NFT on the Blockchain?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Find your NFT within your wallet and click to open it. Once the NFT is open, select the 3 dots on the top left and click "Mint on Polygon"
                    </p>
                    <p>
                        Enter your polygon address or use the one provided. Click "Mint" twice to finalize. You will not be charged a fee (gas) to mint your NFT.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What does “drop” mean?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        You can “drop” your NFT on the global map, but please be careful. When you “drop” your Vatom NFT on the map, anyone who is nearby could come pick it up and take it.. You will be able to see your NFT on the Map and in Augmented Reality through the wallet’s AR Camera in the footer of <a href="wallet.vatom.com" target="_blank" rel="noreferrer">wallet.vatom.com.</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Can I sell an NFT?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes, the artwork in your wallet is yours to own, display, sell, or "drop" out in the world for someone else to find. What you decide to do with your NFT is completely up to you.
                    </p>
                    <p>
                        You can sell your NFT in the Vatom Marketplace for USD or Etherium, without Minting or paying Gas Fees. With the NFT open, click the three ellipses in the top-right and choose “Sell”. You can then set your price and list it on the Vatom Marketplace. In order to list items for sale, you will need to provide your banking information. <a href="https://help.vatom.com/how-do-i-list-an-nft-to-sell" target="_blank" rel="noreferrer">https://help.vatom.com/how-do-i-list-an-nft-to-sell</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Can I download my NFT outside of my digital wallet?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        You can transfer your NFT to another crypto Wallet.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I know the value of my NFT?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        The limited edition State Farm NFTs are only available to win during the Neighborhood Quest game period, and the value of the NFT is decided by the prices others are willing to pay for the NFT.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How long will my NFT be available in my digital wallet?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        This NFT wallet and the items in it are yours forever. However, State Farm will be removing company email addresses from the system at the end of the year. If you added your phone number during registration, you can always use this as your account login.
                    </p>
                    <p>
                        Here’s how to add your personal email or phone number to your Neighborhood Quest Wallet account.
                    </p>
                    <ol>
                        <li>Log into wallet.vatom.com with your State Farm email, or press the “back arrow” from the Neighborhood Quest game to return to your main wallet page.</li>
                        <li>Click your profile picture in the top-right corner.</li>
                        <li>Choose “Manage My Account”</li>
                        <li>“Add” a new Identity</li>
                        <li>Log in with your personal email or phone and confirm the 4-digit Magic Code</li>
                    </ol>
                    <p>
                        If you have already registered for a Vatom Account with your personal information, you will have to use a different email or phone number to connect to this State Farm account.
                        <a href="http://help.vatom.com/add-new-identity" target="_blank" rel="noreferrer">http://help.vatom.com/add-new-identity</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <p>GAME INSTRUCTIONS</p>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Who do I contact for help while playing Neighborhood Quest?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <a href="mailto:support@vatom.com">support@vatom.com</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            What is Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Neighborhood Quest is a mobile game designed to internally spread the Good Neighbor spirit amongst
                            employees as they use a personal mobile device or tablet to search. find, and collect digital{" "}
                            <a href="https://goodneighborcenter.statefarm.com/our-success/logo-evolution/index.html" target="_blank" rel="noreferrer" >State Farm logos.</a> The collectible logos are placed throughout  the United States via augmented reality, giving players the opportunity to win up to four levels of prizes.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Do I need to register to play Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Employees will need to register to play at <a href="/">www.sfneighborhoodguest.com</a>. You will be
                            required to use your State Farm email address to register. You will receive an email confirmation to your State Farm email address from support@vatominc.com. This message will contain a code to return to the State Farm Neighborhood Quest website, which you will be prompted to enter in order to confirm your registration for the game. Registration for Neighborhood Quest will begin on June 2, 2022.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            When does the game start?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The game begins 12:00 a.m. ET on June 7. 2022 and ends 11:59 p.m. ET on June 9, 2022.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            Who can play Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Legal residents of the fifty (50) United States and the District of Columbia, who, as of the
                            start of the Promotion Period. are the age of majority or older in their state of residence
                            (19+ in AL and NE. 18+ in all other states) and are a non-executive (below A VP-level) State
                            Farm or State Farm Fire & Casualty Company employee (excluding all temporary or leased
                            employees) Participants will not include external associates, retirees. agents or their staff.
                            All hybrid and remote employees (excluding executives) will be eligible to play Neighborhood
                            Quest.
                        </p>
                        <p>
                            <strong>Hub and Bloomington employees</strong> assigned to a Bloomington (Corporate. Corporate South.
                            MRSF, ILOC, Oakland Ave, Airport Hanger. SF Park, Research Lab, Flex Storage Facility) or
                            hub location (Cityline 1-4, Citylink, Marina Heights 2-5, Marinalink, Park Center 2/3) will be
                            eligible to win swag and digital prizes. Swag prizes must be redeemed in-person at one of the on-site block parties
                            (6/15/22, 6/23/22 or 6/28/22) at your assigned location.
                            Register <a href="https://www.events.statefarm/rsvp/" target="_blank" rel="noreferrer" >here</a> to attend.
                            Redemption of swag prices will <strong>ONLY</strong> be offered during the onsite block parties. Digital
                            prizes will be redeemed through Vatom wallet.
                        </p>
                        <p>
                            <strong>Non-hub/non-Bloomington employees</strong> (prior operation centers. Regent Commons, ISCs,
                            CLCs, proximity) will be eligible to win digital prizes which will be redeemed through Vatom
                            wallet.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            Where can I find the rules for Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            You can access the official rules of the game <a href='https://resources.vatom.com/PoIvoqVCm3/terms/sf_official_neighborhood_quest_rules.pdf' target="_blank" rel="noreferrer" >here</a>. To the extent the information provided if
                            these FAQs contradicts the Official Rules, the Official Rules will govern.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            What type of prizes can I win?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Employees can play to win amazing prizes like NFTs, gift cards, exclusive experiences
                            valued up to $500. dinner, tickets or State Farm swag!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            I am a non-hub/non-Bloomington employee, what type of prizes am I able to win in Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, all State Farm Employees can play. <strong>Non-hub/non-Bloomington employees</strong> (prior
                            operation centers, Regent Commons, ISCs, CLCs, proximity) will be eligible to play
                            Neighborhood Quest and win digital prizes like gift cards, NFTs and exclusive experiences
                            valued up to $500.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I play Neighborhood Quest from my workstation?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            No, Neighborhood Quest must be played from your personal phone or tablet.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I still participate if I don't have a personal phone or tablet?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            To enter and participate in the Neighborhood Quest by mail, send a letter or postcard with
                            your valid State Farm email address, name, address, city, state, ZIP code, and phone
                            number to Infinity Marketing Team, LLC, c/o State Farm Neighborhood Quest Contest,
                            8575 Higuera Street, Culver City, CA 90232. After receipt of one (1) mail-in game play entry,
                            Sponsor will send you one (1) prize token to the State Farm email address provided for
                            participating. Mail-in game play entries must be hand-written and original. Limit one (1) mailin
                            game play entry per outer envelope. with a maximum of four (4) game play entries per
                            person. Mail-in game play entries must be post-marked by the end of the Promotion Period
                            and received by the deadlines set forth below.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I play Neighborhood Quest during work hours?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Playing Neighborhood Quest is voluntary and not considered work so the game should not be played
                            during work hours. Employees may play Neighborhood Quest during their breaks and
                            during any time off period.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Why do I need to use my personal device to play Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Participants are required to use a personal mobile device or tablet for the optimal
                            game experience.
                            The game is mobile only and requires both a funtional camera and GPS. as well as the ability to move
                            all around with the device. Neither State Farm nor personal laptops can be used to play the
                            game.
                        </p>
                        <p>
                            Normal internet access or charges related to device usage imposed by mobile carriers may apply to registrations submitted via personal devices.
                            Messaging and data rates apply to internet
                            access via personal devices. Participants are solely responsible for all costs or expenses
                            associated with participation in the Contest.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I play Neighborhood Quest from home?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Neighborhood Quest can be played from anywhere.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            How do I play the State Farm Neighborhood Quest?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Click the “Neighborhood Quest” icon to open game play. From there you can
                            select “Level 1” to get started. Read the instructions and then select the “Click to Start
                            Camera”. This will open the AR viewer where you can find digital logos around
                            you. Tap on each digital logo to add it to your count. Once you collect them all for
                            that level, you will receive a reward ticket that you can reveal to see your prize.
                            Make sure to complete all levels to receive more prizes.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton >
                            Do I have to complete level 4?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            No – level 4 is a bonus level and a way to share the game with friends who have not registered yet.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Why isn’t my AR camera working?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            This game uses your location and camera to determine what digital logos are around you. If your permissions are turned off for your browser (safari, chrome, etc.), the game will not work.
                            <br></br>
                            <br></br>
                            To fix this:
                        </p>
                        <ul>
                            <li>iPhone (iOS): Ensure that you are using iOS 11.0 or higher. Go to Settings {"->"} Scroll to safari {"->"} Scroll down to “settings for websites” {"->"} Open “camera” {"->"} Select “ask” {"->"} Location {"->"} Select “ask” {"->"} Try again (you may need to refresh)</li>
                            <li>Android: Ensure that you are using android 11 or higher. Go to Settings {"->"} Apps → Chrome {"->"} Permissions {"->"} Camera “only while app is in use {"->"} Location “only when app is in use” {"->"} Try again (you may need to refresh)</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            “I need a text version of Mary’s introduction video for the Neighborhood Quest game, where is that located?”
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            You can access the text version of Mary & Paul’s video <a href="https://collab.sfcollab.org/sites/WSS006925/Shared%20Documents/Celebration%20to%20100%20Project%20Team/100th%20Execution%20Phase/Communications/Neighborhood%20Quest%20Script%20-%20Schmidt_4.11.22.docx?d=w63bba992c9bc4b6d9e997e7969d7c675" target="_blank" rel="noreferrer">here</a>.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Who do I contact If I have accessibility questions/concerns?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            <a href="mailto:support@vatom.com">support@vatom.com</a>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default Faq;