// import { useState } from 'react';
import './App.css';

declare const window:any;

function Ended() {

	const gotoWallet = () => {

		const analytics = window.analytics
		//console.log('start exit procedures')
		// Log page analytics event
		if (typeof analytics !== 'undefined')
			analytics.track("loadWallet");

		var url = new URL(window.location.href)
		console.info("analytics", analytics)
		url.searchParams.append("anonymousId", analytics?.user().anonymousId())
		window.open(`https://wallet.vatom.com/b/statefarm${url.search}`, '_self', 'noopener,noreferrer')
	}

	return (
		<>
			<div className="container">
				<div className="center">
					<div className="content" style={{minHeight:"100vh"}}>
						<div style={{flex:1}}>
							<img className="logo" src={require("./State-Farm-Logo.png").default} style={{}} alt="State Farm Logo" />
							<div style={{ display: 'flex' }}>
								<div className="text-container">
									<p className="header">
									The State Farm<br></br>
									Neighborhood Quest<br></br>
									has come to an end.
									</p>
									<p className="desc-text">
									Thank you for playing the game and being apart of celebrating State Farm's 100th anniversary.
									</p>
									<p className="desc-text">
									You may continue to view and redeem your prizes in your Rewards Wallet.
									</p>
									<button className="cta" onClick={gotoWallet}>
										Return to Wallet
									</button>
								</div>
							</div>

						</div>
						<div className="image-container">
							<img src={require('./sf_oval.png').default} style={{ maxHeight: "45vh", maxWidth: "100%", objectFit: 'contain', alignSelf: 'flex-end' }} alt="" />
							<span style={{ display: 'block', color: 'white', fontSize: "0.7rem" }}>State Farm Neighborhood Quest is open to employees of State Farm and US residents only.</span>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}

export default Ended;
